<template>
  <v-sheet>
    <v-footer :fixed="true" color="transparent">
      <v-row justify="center" align="center" class="footer">
        <v-col cols="12" sm="12">
          <v-card-actions>
            <!-- <v-btn color="white" fab @click="shareMyprofileAction()"><v-icon left> mdi-export-variant</v-icon></v-btn> -->
            <v-spacer />
            <v-btn color="primary" fab @click="editMyprofileAction()"><v-icon left> mdi-pencil</v-icon></v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-footer>
  </v-sheet>
</template>

<script>
export default {
  methods: {
    editMyprofileAction() {
      this.$router.push({ name: 'editprofileroute' })
    },
    shareMyprofileAction() {}
  }
}
</script>

<style scoped>
.footer {
  margin: 0;
}

.centered {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}
</style>
